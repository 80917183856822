

import React, {Component} from 'react';
import GoogleMap from "@ui/google-map";

class GoogleMapSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: 'https://calendar.google.com/calendar/embed?mode=WEEK&src=1trnk46p4gae8ev410ku57sba4%40group.calendar.google.com&ctz=Australia%2FSydney'
        };
    }

    render() {
        return (
            <div className="GoogleMapSection">
                <GoogleMap source={this.state.src} />
            </div>
        );
    }
}



export default GoogleMapSection
