import React from 'react';

const GoogleMap = ({ source }) => {

    if (!source) {
        return <div>Loading...</div>;
    }

    const src = source;     
    return (
        // basic bootstrap classes. you can change with yours.
        <div className="col-md-12">
            <div className="emdeb-responsive">
                <iframe src={src} 
				 width="100%"
				style={{ border: 0, height: 600 }}
				></iframe>
            </div>
        </div>
    );
};

export default GoogleMap;